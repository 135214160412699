import React from 'react'
import { Helmet } from 'react-helmet'
import I18n from '../../utils/I18n'
import PrimaryMatchCard from './PrimaryMatchCard'
import SecondaryMatchCard from './SecondaryMatchCard'
import SocialShare from '../SocialShare'

const QuizResults = ({lang, scores}) => {
    const GATSBY_S3_PATH = process.env.GATSBY_S3_PATH
    const i18n = new I18n({lang})
    const downloadImageRole = scores[0].download
    const downloadImagePath = `${GATSBY_S3_PATH}/quizapp/downloads/${downloadImageRole}.jpg`
    const trailheadUrl = process.env.GATSBY_ENV === 'production' ? 'https://trailhead.salesforce.com' : 'https://ext.trailhead.sfdc.sh'
    const url = `${trailheadUrl}/${lang}/career-path/match/${downloadImageRole}`

    console.log(url)

    const downloadResults = async () => {
        const response = await fetch(downloadImagePath)
        const blobImage = await response.blob()
        const href = URL.createObjectURL(blobImage)
        const anchorElement = document.createElement('a')
        const nameOfDownload = `my-career-match-${downloadImageRole}.png`

        anchorElement.href = href
        anchorElement.download = nameOfDownload
      
        document.body.appendChild(anchorElement)
        anchorElement.click()

        if (process.env.NODE_ENV === "production") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "eventTracker",
                eventCat: "Downloads",
                eventAct: "Image",
                eventLbl: nameOfDownload,
                'nonInteraction': 'false'
            })
        }
      
        document.body.removeChild(anchorElement)
        window.URL.revokeObjectURL(href)
    }

    const shareOnFacebook = () => {

    }

    const shareOnTwitter = () => {

    }

    const shareOnLinkedIn = () => {

    }

    return (
        <div style={{paddingBottom: '40px'}}>
            {downloadImageRole && (
                <Helmet>
                <meta property="og:url" content={`https://trailhead.salesforce.com/career-path/${downloadImageRole}`} />
                <meta property="og:image" content={downloadImagePath} />
                <meta property="og:image:type" content="image/jpeg" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image" content={downloadImagePath} />
                </Helmet>
            )}

            <div className="container slds-grid slds-wrap" grid="align-center">
                <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_3-of-5" style={{paddingRight: '25px'}}>
                    <h1 className="for-mobile-large" type-style="display-2" style={{paddingBottom: '5px !important'}}>{i18n.text('quiz_results_title')}</h1>
                    <p type-style="body-2" className="center-on-mobile" style={{paddingBottom: '20px'}}>{i18n.text('quiz_results_description')}</p>
                </div>
                <div className="hide-on-mobile slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_2-of-5">
                    <img
                        className="move-up"
                        src={`${GATSBY_S3_PATH}/career-path-v3/career-match/images/results-hero.png`}
                        alt="Astro in Trailhead hat with hands up at the top of a trail celebrating."
                    />
                </div>
            </div>

            <div className="container">
                <PrimaryMatchCard lang={lang} data={scores[0]} />

                <h2 type-style="display-3" style={{fontSize: '24px'}}>{i18n.text('quiz_explore_title')}</h2>

                <div className="slds-grid slds-wrap slds-grid_pull-padded slds-grid_vertical-stretch">
                    <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-3 slds-p-horizontal_small">
                        <SecondaryMatchCard lang={lang} data={scores[1]} rank="2" />
                    </div>

                    <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-3 slds-p-horizontal_small">
                        <SecondaryMatchCard lang={lang} data={scores[2]} rank="3" />
                    </div>

                    <div className="slds-size_1-of-1 slds-medium-size_1-of-2 slds-large-size_1-of-3 slds-p-horizontal_small">
                        <div className="download-card">
                            <h2 type-style="display-3" style={{fontSize: '2rem; text-align: center'}}>{i18n.text('quiz_download_and_share_title')}</h2>
                            
                            <p type-style="body-1" style={{padding: '0 0 25px'}}>{i18n.text('quiz_download_and_share_description')}</p>
                            
                            <button onClick={downloadResults} className="btn btn-blue">{i18n.text('quiz_button_download')}</button>

                            <div className="social-buttons">
                                <SocialShare lang={lang} type="results" network="facebook" url={url} />
                                <SocialShare lang={lang} type="results" network="twitter" url={url} />
                                <SocialShare lang={lang} type="results" network="linkedin" url={url} />
                            </div>

                            <a 
                                href="https://trailhead.salesforce.com/trailblazer-community/groups/0F9300000001omnCAA?tab=discussion&sort=LAST_MODIFIED_DATE_DESC" 
                                target="_blank" 
                                className="btn btn-secondary">
                                    {i18n.text('quiz_button_trailblazer')}
                            </a>
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuizResults
