import React from 'react'
import MatchLayout from '../layouts/MatchLayout'
import QuizSteps from '../components/quiz/QuizSteps'

const QuizTemplate = (props) => {
  const {pageContext: {lang, pageStep, pageType, role, slug}} = props

  return (
    <MatchLayout lang={lang} slug={slug}>
      <QuizSteps lang={lang} pageStep={pageStep} pageType={pageType} role={role} />
    </MatchLayout>
  )
}

export default QuizTemplate