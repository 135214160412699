import React from 'react'
import I18n from '../utils/I18n'

const SocialShare = ({ lang, network, type, url }) => {
    const i18n = new I18n({lang})

    let className = ''
    let href = ''
    let image = ''

    if(network.toLowerCase() === 'facebook') {
        className = 'share-network-facebook'
        const facebookBase = 'https://www.facebook.com/sharer/sharer.php'
        const hashtag = '%23Trailhead'
        const url2 = encodeURIComponent(`${url}&hashtag=${hashtag}`)
        href = `${facebookBase}?u=${url2}`
        image = 'facebook_icon.png'
    }

    if(network.toLowerCase() === 'twitter') {
        href = 'https://ctt.ac/V0TXN'
        image = 'twitter_icon.png'

        if(type === 'results') {
            const title = encodeURIComponent('Trailblazer Career Match')
            href = `https://twitter.com/intent/tweet?text=${title}&url=${encodeURIComponent(url)}&hashtags=Trailhead,TrailblazerCommunity`
        }
    }

    if(network.toLowerCase() === 'linkedin') {
        className = 'share-network-linkedin'
        href = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`
        image = 'linkedin_icon.png'
    }

    const clickAction = () => {
        // GTM ACTIONS 
        if(network.toLowerCase() === 'facebook') {
            // Share  on Facebook
        }
    }

    return (
        <>
            {type === 'home' && (
                 <a className={className} target="_blank" href={href}>
                    <img src={`https://developer.salesforce.com/resources2/quizapp/${image}`} alt="" className="icon-sm"/>
                </a>
            )}

            {type === 'results' && (
                <a onClick={clickAction} className="btn btn-secondary" target="_blank" href={href}>
                    {i18n.text(`quiz_button_${network}`)}
                </a>
            )}
        </>
    )
}

export default SocialShare
