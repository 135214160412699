import React, { useState, useEffect, useReducer, useCallback, useContext } from 'react'
import {arrayMoveImmutable} from 'array-move'
import I18n from '../../utils/I18n'
import QuizHeader from './QuizHeader'
import QuizProgressBar from './QuizProgressBar'
import SortableList from './SortableList'
import QuizResults from './QuizResults'
import allQuizData from "../../../data/quizData.json"

const QuizSteps = ({lang, pageStep, pageType, role}) => {
  const i18n = new I18n({lang})
  const quizDataEN = allQuizData.filter(item => item.lang === 'en')
  const quizDataLang = allQuizData.filter(item => item.lang === lang)
  const quizData = quizDataLang && quizDataLang.length === 1 ? quizDataLang[0] : quizDataEN[0]
  const {quiz_score, stepsData, dragInstructions, quiz_questions: questions} = quizData
  const pages = ['quiz-values', 'quiz-purpose', 'quiz-preferences', 'results']

  
  // --- STATE ---
  const [currentStep, setCurrentStep] = useState(null)
  const [currentQuestion, setCurrentQuestion] = useState([])
  const [currentAnswers, setCurrentAnswers] = useState([])
  const [currentAnswerLabels, setCurrentAnswerLabels] = useState([])
  const [scores, setScores] = useState({})
  // --- END OF STATE


  // --- EFFECTS ---
  useEffect(() => {
    let sessionQuizData = JSON.parse(sessionStorage.getItem('quizData'))

    if(process.env.GATSBY_ENV !== "production" && pageType === 'role_emulation') {
      setCurrentStep(pageStep)

    } else {
      if(pageStep !== 1 && (!sessionQuizData || !Object.hasOwnProperty.call(sessionQuizData, 'step'))) {
        window.location.href = '/career-path/quiz-values'
      
      } else {
        if(!sessionQuizData) {
          sessionQuizData = {
            step: 1, 
            userAnswers: {
              'initial_answers': questions.initial_answers,
              'adaptability_answers': questions.adaptability_answers,
              'efficiency_answers': questions.efficiency_answers,
              'empathy_answers': questions.empathy_answers,
              'innovation_answers': questions.innovation_answers
            }
          }
  
          sessionStorage.setItem('quizData', JSON.stringify(sessionQuizData))
        }
        
        sessionQuizData.step = pageStep
        sessionStorage.setItem('quizData', JSON.stringify(sessionQuizData))
        setCurrentStep(pageStep)
      }
    }
    
  }, [pageStep])

  useEffect(() => {
    if(currentStep > stepsData.length) {
      console.log('GOOD')
      if(process.env.GATSBY_ENV !== "production" && pageType === 'role_emulation') {
        calculateScore(true)
      
      } else {
        setQuestion()
        setAnswers()
        calculateScore()
      }
    } else {
      setQuestion()
      setAnswers()
    }
  }, [currentStep])
  // --- END OF EFFECTS
  

  // --- CALLBACKS ---
  const toSlug = (s) => {return s.toLowerCase().replace(/ /g, '_')}

  const setQuestion = () => {
    const questionIndex = `question_${currentStep}`
    setCurrentQuestion(quizData.quiz_questions[questionIndex])
  }

  const setAnswers = () => {
    const sessionQuizData = JSON.parse(sessionStorage.getItem('quizData'))
    const sessionUserAnswers = sessionQuizData.userAnswers
    const answerGroupIndex = getAnswerGroupId()
    let answerItems = questions[answerGroupIndex]

    if(sessionUserAnswers && Object.hasOwnProperty.call(sessionUserAnswers, answerGroupIndex)) {
      answerItems = sessionUserAnswers[answerGroupIndex]
    }

    const answerLabels = answerItems.map(item => questions[answerGroupIndex].filter(answer => answer.id === item.id)[0].text)
    console.log(answerItems)
    setCurrentAnswers(answerItems)
    setCurrentAnswerLabels(answerLabels)
  }

  const onSortItems = ({oldIndex, newIndex}) => {
    const answerGroupIndex = getAnswerGroupId()

    if(newIndex !== oldIndex) {
      const answers = arrayMoveImmutable(currentAnswers, oldIndex, newIndex)
      const sessionQuizData = JSON.parse(sessionStorage.getItem('quizData'))
      sessionQuizData.userAnswers[getAnswerGroupId()] = answers
      sessionStorage.setItem('quizData', JSON.stringify(sessionQuizData))

      const answerLabels = answers.map(item => questions[answerGroupIndex].filter(answer => answer.id === item.id)[0].text)

      setCurrentAnswers(answers)
      setCurrentAnswerLabels(answerLabels)
    }
  }

  const getAnswerGroupId = () => {
    const storageQuizData = JSON.parse(sessionStorage.getItem('quizData'))
    const storageUserAnswers = storageQuizData.userAnswers
    let id = 'initial_answers'

    if(currentStep > 1) {
      id = `${storageUserAnswers['initial_answers'][currentStep-2].id}_answers`
    }

    return id
  }

  // const convertArrayToObject = (arr, key, valueKey) => {
  //   return arr.reduce(
  //     (previousObject, currentObject) => {
  //       return Object.assign(previousObject, {
  //         [currentObject[key]]: currentObject[valueKey]
  //       })
  //     },
  //   {})
  // }

  const sortScores = (scores) => {
    const scoresCloned = JSON.parse(JSON.stringify(scores))
    return scoresCloned.sort((a, b) => (a.total_score < b.total_score ? 1 : -1))
  }

  const calculateScore = (emulate = false) => {
    const scores = JSON.parse(JSON.stringify(quiz_score))

    if(emulate) {
      //
      const r_score = scores.find((x) => toSlug(x.title) === toSlug(role))
      r_score.score_q1 = 3
      r_score.score_q2 = 2
      r_score.score_q3 = 2
      r_score.total_score = 7

    } else {
      const storageQuizData = JSON.parse(sessionStorage.getItem('quizData'))
      const storageUserAnswers = storageQuizData.userAnswers

      // Caculate Step 1
      storageUserAnswers.initial_answers.forEach((answer, i) => {
        answer.roles.forEach((r) => {
          const r_score = scores.find((x) => x.title === r)

          // 3 points for roles in first place, 2 points for roles in second place, 1 point for roles in third place
          const points = i==0 ? 3 : (i==1 ? 2 : (i==2 ? 1 : 0));
          r_score.score_q1 = r_score.score_q1 + points
        })
      })

      // Caculate Step 2
      const group_2_id = `${storageUserAnswers['initial_answers'][0].id}_answers`
      
      storageUserAnswers[group_2_id].forEach((answer, i) => {
        answer.roles.forEach((r) => {
          const r_score = scores.find((x) => x.title === r)
          
          // 2 points for roles in first place, 1 point for roles in second place
          const points = i==0 ? 2 : (i==1 ? 1 : 0);
          r_score.score_q2 = r_score.score_q2 + points
        })
      })


      // Caculate Step 3
      const group_3_id = `${storageUserAnswers['initial_answers'][1].id}_answers`
      
      storageUserAnswers[group_3_id].forEach((answer, i) => {
        answer.roles.forEach((r) => {
          const r_score = scores.find((x) => x.title === r)

          // 2 points for roles in first place, 1 point for roles in second place
          const points = i==0 ? 2 : (i==1 ? 1 : 0);
          r_score.score_q3 = r_score.score_q3 + points
        })
      })

      // Calculate Total Score
      scores.forEach((r) => {
        r.total_score = r.score_q1 + r.score_q2 + r.score_q3
      })
    }

    // Sort based on Total Score
    const sortedScores = sortScores(scores)
    setScores(sortedScores)
  }

  const goToPreviousStep = () => {
    const sessionQuizData = JSON.parse(sessionStorage.getItem('quizData'))
    if(sessionQuizData.step + 1 > 1) {
      const nextStep = sessionQuizData.step - 1
      sessionQuizData.step = nextStep
      sessionStorage.setItem('quizData', JSON.stringify(sessionQuizData))

      window.location.href = `/${lang}/career-path/${pages[nextStep-1]}`
    }
  }

  const goToNextStep = () => {
    const sessionQuizData = JSON.parse(sessionStorage.getItem('quizData'))

    if(sessionQuizData.step + 1 <= stepsData.length) {
      const nextStep = sessionQuizData.step + 1
      sessionQuizData.step = nextStep
      sessionStorage.setItem('quizData', JSON.stringify(sessionQuizData))

      window.location.href = `/${lang}/career-path/${pages[nextStep-1]}`
    }
  }

  const goToResults = () => {
    const sessionQuizData = JSON.parse(sessionStorage.getItem('quizData'))
    if(sessionQuizData.step + 1 > stepsData.length) {
      sessionQuizData.step = currentStep + 1
      sessionStorage.setItem('quizData', JSON.stringify(sessionQuizData))

      window.location.href = `/${lang}/career-path/${pages.pop()}`
    }
  }
  // --- END OF CALLBACKS

  
  // --- CONTENT ---
  return (
    <div>
      
      {currentStep <= stepsData.length && (
        currentStep && (
          <>
            <QuizHeader title={i18n.text('quiz_title')} description={i18n.text('quiz_description')} currentStep={currentStep} stepsData={stepsData} />
            <QuizProgressBar currentStep={currentStep} stepsData={stepsData} />
  
            <div className="container">
              <div style={{maxWidth: '800px', margin: '0 auto'}}>
                <h2 type-style="display-3" className="text-center" dangerouslySetInnerHTML={{ __html: currentQuestion }} />
                <p type-style="body-3" className="small text-center">{dragInstructions}</p>
  
                {
                  currentAnswers && (
                    <SortableList items={currentAnswerLabels} onSortEnd={onSortItems} helperClass="dsc-dragging-item" lockAxis="y" />
                  )
                }
  
                <div style={{textAlign: 'center'}} className="slds-text-aling_center">
                  {currentStep > 1 && currentStep <= stepsData.length && (
                    <button className="btn btn-transparent" onClick={goToPreviousStep}>{i18n.text('quiz_button_back')}</button>
                  )}
                      
                  {currentStep < stepsData.length && (
                    <button className="btn btn-blue" onClick={goToNextStep}>{i18n.text('quiz_button_next')}</button>
                  )}
  
                  {currentStep === stepsData.length && (
                    <button className="btn btn-blue" onClick={goToResults}>{i18n.text('quiz_button_results')}</button>
                  )}
                </div>
  
              </div>
            </div>
  
            <div className="quiz-footer">
              <img src="https://developer.salesforce.com/resources2/career-path-v3/match/images/quiz-footer.png" alt="" />
            </div>
          </>
        )
      )}

      {currentStep > stepsData.length && scores.length > 0 && (
        <QuizResults lang={lang} scores={scores} />
      )}

    </div>
  )
}

export default QuizSteps
