/* eslint-disable no-unused-expressions */
import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import TagManager from 'react-gtm-module'
import langsObj from '../utils/Langs'
import '../assets/stylesheets/matchStyles.scss'

export const MatchLayoutQuery = graphql`
  query MatchLayoutQuery {
    site {
      siteMetadata {
        title
        keywords
        description
        tdsPath
        tmPath
      }
    }
  }
`

function MatchLayout({ children, lang, title, description, socialImage, url, slug }) {

  const langCode = langsObj.getLangsCodes()
  const isoCode = langCode[lang] ? langCode[lang] : 'en';

  useEffect(() => {
    TagManager.initialize({gtmId: 'GTM-KNP8948'})
  },[])

  return (
    <StaticQuery 
      query={MatchLayoutQuery} 
      render={({ site: { siteMetadata } }) => (
        <>
          {/* prettier-ignore */}
          <Helmet>
            <title>{siteMetadata.title}</title>
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta charset="utf-8" />
            <meta name="title" content={title} />
            <meta name="description" content={description} />

            <link rel="canonical" href={`https://trailhead.salesforce.com/career-path/${slug}`} />

            <meta http-equiv="content-language" content={isoCode} />
            <link href={`https://trailhead.salesforce.com/career-path/${slug}`} hreflang="x-default" rel="alternate"></link>

            {
              Object.keys(langCode).map(key => {
                return (<link href={`https://trailhead.salesforce.com/${key}/career-path/${slug}`} hreflang={langCode[key]} rel="alternate"></link>)
              })
            }

            <meta property="og:title" content={title} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={url} />
            <meta property="og:image" content={socialImage} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />

            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:url" content={url} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image" content={socialImage} />

            {
              // Google Tag Manager
            /*
            <script 
              dangerouslySetInnerHTML={{
                __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var
                  f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','GTM-KNP8948');`
              }}
            />
            */
            
              // End Google Tag Manager
            }

            <script type="text/javascript" async="" src="https://play.vidyard.com/embed/v4.js"></script>
          </Helmet>
          
          <div className="contentArea contentMatch">
            
            {children}

            <noscript 
              dangerouslySetInnerHTML={{
                __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KNP8948" height="0" width="0"
                  style="display:none;visibility:hidden"></iframe>`
              }}
            />
          </div>
        </>
      )}
    />
  )
}

MatchLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default MatchLayout